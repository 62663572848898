'use client'

import { useState } from 'react'

export default function Component() {
  const [selectedSeats, setSelectedSeats] = useState<number[]>([])
  const [hoveredSeat, setHoveredSeat] = useState<number | null>(null)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const toggleSeat = (seatNumber: number) => {
    setSelectedSeats(prev =>
      prev.includes(seatNumber)
        ? prev.filter(seat => seat !== seatNumber)
        : [...prev, seatNumber]
    )
  }

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    const svg = event.currentTarget
    const point = svg.createSVGPoint()
    point.x = event.clientX
    point.y = event.clientY
    const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse())
    setMousePosition({ x: svgPoint.x, y: svgPoint.y })
  }

const generateSeats = (rows: number, seatsPerRow: number): JSX.Element[] => {
  const seats: JSX.Element[] = [];

  for (let row = 0; row < rows; row++) {
    for (let seat = 0; seat < seatsPerRow; seat++) {
      const seatNumber = row * seatsPerRow + seat + 1;
      const angle =
        ((seat - (seatsPerRow - 1) / 2) * Math.PI) / (seatsPerRow + 1);
      const x = 150 + (120 - row * 20) * Math.sin(angle);
      const y = 150 - (120 - row * 20) * Math.cos(angle);

      seats.push(
        <circle
          key={seatNumber}
          cx={x}
          cy={y}
          r="5"
          fill={selectedSeats.includes(seatNumber) ? 'red' : 'white'}
          className="cursor-pointer"
          onClick={() => toggleSeat(seatNumber)}
          onMouseEnter={() => setHoveredSeat(seatNumber)}
          onMouseLeave={() => setHoveredSeat(null)}
        />
      );
    }
  }

  return seats;
};


  const totalPrice = selectedSeats.length * 120

  return (
    <div className="flex flex-col items-center p-4 min-h-screen bg-gray-800 text-white">
      <h1 className="text-2xl font-bold mb-1">Rebollo's Concert</h1>
      <p className="text-sm mb-8">Select your seats</p>
      <div className="w-full max-w-md">
        <svg viewBox="0 0 300 200" className="w-full" onMouseMove={handleMouseMove}>
          <path d="M0,150 A150,150 0 0,1 300,150" fill="none" stroke="white" strokeWidth="2" />
          {generateSeats(5, 10)}
          <text x="150" y="175" textAnchor="middle" className="text-xl fill-current">🎸</text>
          <text x="150" y="195" textAnchor="middle" className="text-xs fill-current">(Rebollo)</text>
          {hoveredSeat && (
            <g>
              <rect
                x={mousePosition.x + 10}
                y={mousePosition.y - 25}
                width="60"
                height="20"
                fill="white"
                stroke="black"
                strokeWidth="1"
                rx="4"
                ry="4"
              />
              <text 
                x={mousePosition.x + 40} 
                y={mousePosition.y - 11} 
                textAnchor="middle" 
                fill="black" 
                fontSize="10"
              >
                Seat {hoveredSeat}
              </text>
            </g>
          )}
        </svg>
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-semibold">Price: ${totalPrice}</h2>
      </div>
    </div>
  )
}