import React, { useEffect } from 'react';
import SeatSelector from './SeatSelector';

declare global {
  interface Window {
    Telegram: any;
  }
}

function App() {
  useEffect(() => {
    // Initialize Telegram WebApp if available
    if (window.Telegram) {
      const telegramWebApp = window.Telegram.WebApp;
      if (telegramWebApp) {
        telegramWebApp.ready();
      }
    }
  }, []);

  return (
    <div>
      <SeatSelector />
    </div>
  );
}

export default App;
